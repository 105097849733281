import '../styles/nprogress.css';
import '../styles/rc-tooltip.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import '../styles/globals.css';
import '../styles/tailwind.css';
import '../styles/fonts/Harietta/style.css';

import Head from 'next/head';
import type { AppProps } from 'next/app';
import { Provider } from 'next-auth/client';
import type { NextPage } from 'next';
import type { ReactElement, ReactNode } from 'react';
import { appWithTranslation } from 'next-i18next';
import useNProgress from '../hooks/useNProgress';
import { Provider as RTKProvider } from 'react-redux';
import store from '../store/store';
import { SkeletonTheme } from 'react-loading-skeleton';
import useRouterScrollToTop from '../hooks/useRouterScrollToTop';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  useNProgress();
  useRouterScrollToTop();
  const getLayout = Component.getLayout ?? ((page) => page);
  return (
    <>
      <Head>
        <title>RETM</title>
      </Head>
      {/* @ts-ignore */}
      <Provider session={pageProps.session}>
        <SkeletonTheme>
          <RTKProvider store={store}>
            <div className="top-of-dashboard" />
            {getLayout(<Component {...pageProps} />)}
          </RTKProvider>
        </SkeletonTheme>
      </Provider>
    </>
  );
}

export default appWithTranslation(MyApp);
