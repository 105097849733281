import React from 'react';
import { UnpackNestedValue, useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ObjectShape } from 'yup/lib/object';
import classNames from 'classnames';
import { DefaultValues } from 'react-hook-form/dist/types/form';

type FormProps<TFormValues> = {
  onSubmit: (
    data: UnpackNestedValue<TFormValues>,
    methods: UseFormReturn<TFormValues>
  ) => void;
  children: (
    methods: [UseFormReturn<TFormValues>, UseFormReturn<TFormValues>]
  ) => React.ReactNode;
  schema?: Record<keyof TFormValues, object>;
  mode?: 'onSubmit' | 'onBlur' | 'onChange' | 'onTouched';
  className?: string;
  defaultValues?: DefaultValues<TFormValues>;
};

const Form = <TFormValues extends Record<string, any> = Record<string, any>>(
  props: FormProps<TFormValues>
) => {
  const methods = useForm<TFormValues>({
    mode: props.mode || 'onSubmit',
    // @ts-ignore
    resolver: yupResolver(yup.object((props.schema as ObjectShape) || {})),
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    defaultValues: props.defaultValues,
  });

  return (
    <form
      className={classNames(props.className)}
      // @ts-ignore
      onSubmit={methods.handleSubmit((data) => props.onSubmit(data, methods))}
    >
      {props.children([methods, methods])}
    </form>
  );
};
export default Form;
