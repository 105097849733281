import LanguageIcon from '../icons/LanguageIcon';
import { TSwitchLanguageProps } from '../../types/app';

const SwitchLanguage = (props: TSwitchLanguageProps) => {
  const CurrentLang = () => {
    if (props.local === 'en')
      return <div className="font-ar pb-1 mx-[16px]">عربي</div>;
    return <div className="font-en mx-[16px]">Eng</div>;
  };

  const onClick = () => {
    props.onClick(props.local);
  };

  return (
    <button
      onClick={onClick}
      className="rtl:mr-[26px] ltr:ml-[20px] text-[12px] text-[#A1A1A1] flex items-center align-middle justify-between rtl:space-x-reverse"
    >
      <LanguageIcon />
      <CurrentLang />
    </button>
  );
};

export default SwitchLanguage;
